import fetchAPI from "./fetchAPI"

export default async function fetchPopupOmit(id) {
  const { fetchPopupOmit } = await fetchAPI(
    `query($id: ID!) {
      fetchPopupOmit(id: $id) {
          show
        }
      }`,
    {
      variables: { id }
    }
  )

  return fetchPopupOmit
}
